/*
* ==========================================================
*   Cards
* ==========================================================
*/
.card {
  display: flex;
  flex-direction: column;
  border: none;
  width: 647px;
  background: #FFFFFF;
  border: 1px solid #ECECEF;
  border-radius: 16px;

  &.card-whitelist {
    width: 100%;

    .card-body {
      gap: 16px;

      table tr td {
        --bs-table-accent-bg: white;
        padding: 16px 24px;
        color: $color-primary;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .card-footer {
    @include media-breakpoint-down(md) {
      padding: 1rem;
    }
  }
  
  .card-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 24px;
    gap: 10px;
    background: inherit;
    border-bottom: 1px solid #ECECEF;

    h5 {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      color: $color-primary;
      margin-bottom: 0;
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 16px;
    gap: 8px;

    &-item {
      display: flex;
      flex-direction: column;
      gap: 6px;

      &.arrow {
        align-items: center;
      }

      .h5 {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: $color-primary;
        margin-bottom: 0;
      }

      .coin-swap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        border-radius: 12px;
        background: #F9FAFA;
        border: 1px solid #ECECEF;
        padding: 12px 16px;
        gap: 16px;

        &.total {
          .coin-swap-item:nth-child(1) {
            flex: 0 0 76px;
          }
          .coin-swap-item:nth-child(2) {
            flex: 0 0 315px;
            padding: 0 12px;
          }
          .coin-swap-item:nth-child(3) {
            flex: 0 0 140px;

            input {
              color: $color-primary;
              font-weight: 400;
              font-size: 15px;
              line-height: 24px;
              padding: 8px 0px 8px 16px;
              background: #FFFFFF;
              border: 1px solid #E3E4E8;
              border-radius: 1000px;
              text-align: right;
            }
          }
        }

        .token-value {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: $color-primary;
        }

        @include media-breakpoint-down(xl) {
          flex-direction: column;

          &.total {
            .coin-swap-item {
              flex: 1 !important;
              width: 100%;

              &:nth-child(2) {
                padding: 0 !important;
                padding-top: 12px;
              }

              &:nth-child(3) {
                padding: 8px 16px !important;
              }
            }
          }
        }

      }
    }
    
    .tooltip-calculate-result {
      margin-top: 4px;
      margin-bottom: 0;
      display: flex;
      flex-direction: row;
      gap: 4px;
      font-weight: 700;
      font-size: 15px;
      line-height: 24px;
      color: $color-secondary;

      small.text-muted {
        color: $color-primary !important;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
      }
    }
  }

  .card-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0px 24px 24px;
    background: inherit;
  }

  &.fundraising {
    width: 100%;

    .card-body {
      display: flex;
      flex-direction: row;
      padding: 24px;
      gap: 16px;
      flex-wrap: wrap;

      &-item {
        display: flex;
        flex-direction: column;
        padding: 20px;
        gap: 20px;
        border: 1px solid #ECECEF;
        border-radius: 12px;
        flex: 1;
        
        p {
          margin: 0;
        }

        &:nth-child(1) {
          justify-content: center;
          align-items: center;

          .details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0px;
            gap: 12px;

            &-title {
              color: $color-secondary;
              font-weight: 400;
              font-size: 15px;
              line-height: 24px;
              text-align: center;
              margin-bottom: 0;
            }

            .tokens-hardcap-usd {
              color: $color-secondary;
              margin-bottom: 0;
              font-weight: 400;
              font-size: 15px;
              line-height: 24px;
              text-align: center;
            }
          }

          .progress-bar-wrapper {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 8px;

            .progress {
              width: inherit;
              border-radius: 1000px;
              height: 12px;

              .progress-bar-striped {
                animation: unset;
                background-image: unset;
                background-color: #40BF95 !important;
              }
            }

            p {
              font-weight: 700;
              font-size: 15px;
              line-height: 24px;
              color: #40BF95;
            }
          }
        }

        &:nth-child(2), &:nth-child(3) {
          padding: 16px;
          gap: 12px;

          .title {
            color: $color-secondary;
            font-weight: 700;
            font-size: 15px;
            line-height: 24px;
          }

          ul {
            list-style: none;

            li {
              position: relative;

              &:not(:first-child) {
                margin-top: 12px;
              }

              &:before {
                content: "";
                position: absolute;
                font-weight: bold;
                display: inline-block;
                width: 8px;
                height: 8px;
                background: #B9BBC6;
                border-radius: 50%;
                top: 8px;
                left: -14px;
              }

              p {
                color: $color-secondary;
                font-weight: 400;
                font-size: 15px;
                line-height: 24px;

                strong, a, b {
                  font-weight: 700;
                  color: $color-primary;
                }
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(xl) {
    width: auto;
  }
}

.section-fundraising-goal, .section-whitelist, .section-trollbox {
  width: 100%;
}

.section-trollbox .card {
  width: 100%;
}

.section-claim {
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .claiming-wrapper {
    flex: 0 0 50%;
  }

  .card {
    width: auto;
    flex: 0 0 calc(50% - 24px);

    &-body {
      display: flex;
      flex-direction: column;
      padding: 24px;
      gap: 16px;

      .claiming-info {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }
  }
}

.card-header-tabs {
  margin-bottom: -$card-spacer-y;

  .nav-link {
    margin-bottom: 0;
    border-bottom: none;
  }
}

.card-header-transparent {
  border-bottom: none;
  background-color: rgba(0, 0, 0, 0.3);
}

.card {
  .list-group-item {
    padding-right: $card-spacer-x;
    padding-left: $card-spacer-x;
  }
}

.card-heading {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: $color-primary;

  @include media-breakpoint-down(md) {
    font-size: 0.8rem;
  }
}

.card-header-more {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: $spacer;
  transform: translateY(-50%);
}

.card-table {
  overflow: hidden;

  .table-responsive {
    margin-right: -1px;
  }

  thead {
    th {
      padding-top: $spacer * 1.5;
      padding-bottom: $spacer * 1.5;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $white;
      border-bottom-width: 0;
      background: $gray-800;
      font-size: 13px;
    }

    &.light {
      th {
        color: $dark;
        background: $gray-100;
      }
    }
  }

  .table tr td:first-child,
  .table tr th:first-child {
    padding-left: $spacer * 2;
  }

  .table tr td:last-child,
  .table tr th:last-child {
    padding-right: $spacer * 2;
  }
}

.card-table-img {
  display: inline-block;
  width: 40px;

  @include media-breakpoint-up(lg) {
    width: 70px;
  }
}

.card-table-text {
  display: inline-block;
  vertical-align: middle;
}

.credit-card {
  position: relative;
  height: 0;
  padding-top: 66%;
  border-radius: 0.5rem;
}

.credict-card-content {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  width: 100%;
  height: 100%;

  padding: 1.5rem;
  justify-content: space-between;
}

.credict-card-bottom {
  display: flex;
  padding-top: 1rem;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}

.card-profile .card-header {
  height: 9rem;
  background-position: center center;
  background-size: cover;
}

.card-profile-img {
  position: relative;
  max-width: 8rem;
  margin-top: -6rem;
  margin-bottom: 1rem;
  border: 3px solid #fff;
  border-radius: 100%;
  box-shadow: $box-shadow;
  z-index: 2;
}

.card-status {
  position: absolute;
  top: -1px;
  right: -1px;
  left: -1px;
  height: 8px;
  background: rgba(0, 40, 100, 0.12);
  @include border-radius(
    $card-inner-border-radius $card-inner-border-radius 0 0
  );
}

.card-img-overlay-top {
  position: absolute;
  top: $card-spacer-y;
  right: $card-spacer-x;
  left: $card-spacer-x;
}

.card-img-overlay-bottom {
  position: absolute;
  right: $card-spacer-x;
  bottom: $card-spacer-y;
  left: $card-spacer-x;
}

.card-widget {
  display: flex;
  padding: map-get($spacers, 4);
  border-radius: $rounded-pill / 10;
  background: $white;
  box-shadow: $box-shadow-lg;
  align-items: center;
  justify-content: space-between;
  .text {
    h6 {
      font-size: 90%;
    }
  }

  &.card-widget-danger {
    box-shadow: 0 1rem 3rem rgba($danger, 0.175);
  }

  &.card-widget-success {
    box-shadow: 0 1rem 3rem rgba($success, 0.175);
  }
}

.card-sale {
  display: flex;
  flex-direction: column;
  width: 647px;
  gap: 12px;

  &.not-allowed {
    gap: 24px;

    .card-sale-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
      gap: 20px;
      background: rgba(255, 163, 163, 0.2);
      border-radius: 12px;

      &-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        color: $color-primary;
        
        .title {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 0;
        }

        .card-text {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 0;
        }

        .list {
          font-weight: 700;
          font-size: 15px;
          line-height: 24px;
          margin-bottom: 0;
        }
      }

      .link-container {
        a {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 12px 24px;
          background: #FFFFFF;
          border: 1px solid #ECECEF;
          box-shadow: 0px 3px 0px #DDDFE3;
          border-radius: 1000px;
          font-weight: 800;
          font-size: 15px;
          line-height: 20px;
          color: $color-primary;
          text-decoration: none;
          transition: all 500ms;

          &:hover {
            background: #F4F4F6;
          }
        }
      }
    }
  }

  &.success {
    gap: 24px;

    .card-sale-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
      gap: 8px;
      background: rgba(147, 241, 131, 0.2);
      border-radius: 12px;
      color: $color-primary;

      .text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;

        h5 {
          margin-bottom: 0;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
        }

        span {
          color: $color-primary;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
        }
      }

      .list {
        margin-bottom: 0;
        color: $color-primary;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;

        .colored-green {
          font-weight: 700;
          font-size: 15px;
          line-height: 24px;
          color: #439834;
        }
      }
    }
  }

  &-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    
    &-icon {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: fit-content;
      padding: 6px 12px 6px 8px;
      gap: 6px;
      background: rgba(147, 241, 131, 0.2);
      border-radius: 50px;
      
      span {
        font-weight: 700;
        font-size: 15px;
        line-height: 24px;
        color: #439834;
      }
    }
  }


  .text {
    display: flex;
    flex-direction: column;
    gap: 12px;
    
    span {
      color: $color-secondary;
      font-weight: 400;
      font-size: 17px;
      line-height: 28px;
    }
  }

  @include media-breakpoint-down(xl) {
    width: 100%;
  }
}

.card-category {
  text-transform: uppercase;
  color: $gray-600;
  font-size: 0.9rem;
  font-weight: bold;
}

.card-adjust-height {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      .card {
        max-height: 50vh;
      }

      .card-body {
        overflow: auto;
        height: calc(100% - 70px);
        margin-bottom: $card-spacer-y;
        padding-bottom: math.div($card-spacer-y, 2);
      }

      @include media-breakpoint-up($next) {
        height: 0;
        min-height: 100%;

        .card {
          height: calc(100% - 24px);
          max-height: none;
        }

        .card-body {
          overflow: auto;
          height: calc(100% - 70px);
          margin-bottom: $card-spacer-y;
          padding-bottom: math.div($card-spacer-y, 2);
        }
      }
    }
  }
}

.card-header.bg-inverse {
  color: $light;
  background: $dark;
}

// card loading/inactive
.card-overlay {
  display: none;
}

.card-inactive {
  position: relative;
  .card-overlay {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    z-index: 999999;
    border-radius: 20px;
  }
}

.card-sale-sold-out {
  img {
    width: 220px;
  }

  @include media-breakpoint-down(md) {
    .card-widget-body {
      display: block;
      text-align: center;
    }
    .icon-lg {
      display: none;
    }
  }
}