.CookieConsent {
	flex-direction: column;
	gap: 1.4em;
	max-width: 387.2px;
	left: unset !important;
	right: 1.25em !important;
	bottom: 1.25em !important;
	border-radius: 16px!important;
	padding: 1.1em 1.8em 1.4em;

	div:first-child {
		flex: unset !important;
		margin: 0 !important;

		.cookie-header {
			display: flex;
			flex-direction: column;
			gap: 0.7em;

			&-title {
				font-size: 18px;
			    font-weight: 700;
			    line-height: 26px;
			    margin-bottom: 0;
			}

			&-description {
				font-size: 15px;
				font-weight: 400;
				line-height: 22px;
			    margin-bottom: 0;
			}
		}
	}
	div:last-child {
		width: 100%;
		
		#rcc-confirm-button {
			background: #fff !important;
		    border: 1px solid #ececef !important;
		    border-radius: 50px !important;
		    box-shadow: 0 3px 0 #dddfe3 !important;
		    color: #393c46 !important;
		    font-family: Nunito !important;
		    font-size: 15px !important;
		    font-style: normal !important;
		    font-weight: 800 !important;
		    line-height: 20px !important;
		    padding: 12px 20px !important;
		    margin: 0 !important;
		    width: 100%;
		}
	}

}