footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  gap: 24px;
  background: #FFFFFF;
  border-top: 1px solid #ECECEF;

  .copyright {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $color-primary;
  }

  .version {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: $color-secondary;
  }
}