// this is the place for your components / CSS

.section-referral {

  .input-group-append {
    .btn {
      border-radius: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .card-transactions-table {
    thead th {
      background: $color-accent;
      color: $gray-900;
    }
  }

  .card-users-table {
    thead th {
    }
  }

  .money {
    letter-spacing: 0.1em;
  }

  .referral-url {
    @include media-breakpoint-down(md) {
      input {
        width: 100%;
        border-radius: 5px !important;
      }
      .input-group-append {
        margin-top: 1rem;
        width: 100%;
      }
      button {
        width: 100%;
        border-radius: 5px !important;
      }
    }
  }

}