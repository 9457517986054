@import "mixins-custom";

.nft-collection {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;

  .nft-collection-item {
    .overlay {
      position: relative;

      .overlay-text {
        position: absolute;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        background: rgba(0, 0, 0, 0.3);
        font-weight: bold;
        font-size: 1.2em;
        z-index: 3;
        height: 100%;
        width: 100%;
        text-align: center;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;

        .label-allocation {
          color: $gray-900;
          display: inline;
          width: 50%;
          margin: auto;
          padding: 5px 0;
          border-radius: 10px;
          background: rgba($color-accent, 0.8);
        }

        .label-name {
          font-weight: bold;
        }
      }
    }
  }
}
