/*
* ==========================================================
*     Page
* ==========================================================
*/


.page-holder {
    min-height: calc(100vh - #{$navbarHeight});
    display: flex;
    flex-direction: column;
    width: 100%;

    &-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 0;
        padding: 32px 24px 48px; 
        grid-gap: 24px;
        gap: 24px;
    }

    @include media-breakpoint-down(xl) {
      overflow: hidden;
      margin-top: 70px;

      section {
        width: 100%;
      }
    }
}

.page-heading {
    color: $color-primary;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.4px;
    margin-bottom: 0;
}
