.icon {
    display: flex;
    width: 2.2rem;
    height: 2.2rem;
    flex-shrink: 0;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    &.icon-lg {
        width: 3rem;
        height: 3rem;
    }

    &.icon-xl {
        width: 5rem;
        height: 5rem;
    }

    &.icon-sm {
        width: 1.7rem;
        height: 1.7rem;
        font-size: 0.7rem;
    }
}

.token-with-ticker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-font-smoothing: antialiased;
    color: $color-primary;
    fill: $color-primary;
    width: 100%;
    font-size: 16px;
    gap: 6px;

    &-details {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: $color-primary;
        gap: 6px;
    }

    img {
        width: 24px;
        height: 24px;
        border-radius: 100%;
    }

    .token-name {
        color: $color-primary;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }

    .token-value {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: bold;
    }
    
    @include media-breakpoint-down(xl) {
        justify-content: flex-start;
    }
}

$socialIconSize: 22px;

.social-list {
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 0;
    margin-bottom: 0;
    gap: 8px;

    .social-list-item {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        box-sizing: content-box;
        transition: background-color 1s linear;
        color: white;
        cursor: pointer;

        width: $socialIconSize;
        height: $socialIconSize;

        padding: 10px;
        background: #FFFFFF;
        border: 1px solid #ECECEF;
        box-shadow: 0px 3px 0px #DDDFE3;
        border-radius: 1000px;
        transition: all 500ms;

        &:hover {
            background: #F4F4F6;
        }
    }

    @include media-breakpoint-down(xl) {
        max-width: 75%;
        justify-content: flex-start;
    }
}

@mixin custom-icon($url, $iconWidth, $iconHeight) {
    display: inline-block;
    width: $iconWidth;
    height: $iconHeight;
    background: url($url);
    background-size: cover;
}

.icon-live {
    display: inline-block;
    position: relative;
    top: -1px;
    background-color: $success;
    width: 8px;
    height: 8px;
    border: 1px solid rgba(darken($success, 30), .1);
    border-radius: 50%;
    z-index: 1;

    &:before {
        content: '';
        display: block;
        position: absolute;
        background-color: rgba($success, .6);
        width: 100%;
        height: 100%;
        border-radius: 50%;
        animation: live 3s ease-in-out infinite;
        z-index: -1;
    }
}

@keyframes live {
    0% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(3.5, 3.5);
        background-color: rgba(red, 0);
    }
}
