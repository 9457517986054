/*
* ===================================================
*    Typography
* ===================================================
*/
@import "mixins-custom";

a {
    transition: all 0.3s;
}

.subtitle {
    font-size: $font-size-base;
    @include uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include font-accent();
}

.section-heading {
    font-size: $font-size-base;
    font-weight: bold;
    @include uppercase;

    &.section-heading-ms {
        margin-left: 1rem;

        @include media-breakpoint-up(lg) {
            margin-left: 2rem;
        }
    }
}

.text-content {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol,
    blockquote {
        margin-bottom: 2rem;
    }

    p,
    ul,
    ol {
        color: $gray-600;
    }
}

.btn.text-uppercase {
    letter-spacing: 0.1em;
}

body {
    h1, h2, h3, h4, h5, h6 {
        font-weight: 900;
    }

    b, strong {
        font-weight: 900;
    }

    .fw-bold {
        font-weight: 900;
    }

    button, a, .btn {
        font-weight: 700;
    }

}

