.section-demo-mint-nft {
  #image-editor {
    height: 500px;
  }

  .background-option {
    img {
      width: 48px;
      height: 48px;
      margin-right: 10px;
    }
  }
}

#modal-image {
  > :first-child {
    width: 90% !important;
    max-width: 700px !important;
  }

  .modal-body {
    padding: 20px 0 20px 0;
  }

  [data-testid="modal-ok-button-test-id"] {
    font-weight: normal !important;
    font-family: $font-family-base !important;

    border-radius: 0.4rem;
    padding: 7px 15px;
    background: $color-accent;
    border: 0;
    color: $gray-900;
  }
}