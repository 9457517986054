@import "mixins-custom";

.card-countdown {
  .countdown-container {
    @include font-accent();

    .countdown {
      display: flex;
      justify-content: space-around;
      align-items: center;
      text-align: center;
    }

    .countdown-element {
      //width: 25%;
      display: inline-block;
      margin: 0 10px;
      @include media-breakpoint-down(xs) {
        margin: 5px;
      }
    }

    .countdown-label {
      margin-top: 10px;
      display: block;
      font-size: 0.8rem;
    }

    .time {
      display: block;
      //color: red;
      letter-spacing: 0.3rem;
      font-size: 2.5rem;
      @include media-breakpoint-down(xlg) {
        font-size: 1.8rem;
      }
      @include media-breakpoint-down(lg) {
        font-size: 1.6rem;
      }
      @include media-breakpoint-down(md) {
        font-size: 1.4rem;
      }
    }
  }
}

.page-header {
  display: flex;
  width: 100%;

  @include media-breakpoint-down(sm) {
    display: block;
  }

  .countdown-inline {
    margin-left: auto;
    @include media-breakpoint-down(sm) {
      margin-top: 15px;
    }

    .countdown-element {
      margin-right: 5px;
    }

    .separator {
      margin-right: 5px;
    }

    .countdown-label {
    }

    .time {
      margin-right: 5px;
      font-weight: bold;
    }
  }

}