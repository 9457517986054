/*
 *  Usage:
 *
      <div class="sk-wave">
        <div class="sk-rect sk-rect1"></div>
        <div class="sk-rect sk-rect2"></div>
        <div class="sk-rect sk-rect3"></div>
        <div class="sk-rect sk-rect4"></div>
        <div class="sk-rect sk-rect5"></div>
      </div>
 *
 */


.sk-wave {
  $rectCount: 5;
  $animationDuration: 1.2s;
  $delayRange: 0.4s;

  margin: $spinkit-spinner-margin;
  width: $spinkit-size * 1.25;
  height: $spinkit-size;
  text-align: center;
  font-size: 10px;

  .sk-rect {
    background-color: $spinkit-spinner-color;
    height: 100%;
    width: 6px;
    display: inline-block;
    animation: sk-waveStretchDelay $animationDuration infinite ease-in-out;
  }

  @for $i from 1 through $rectCount {
    .sk-rect#{$i} { animation-delay: - $animationDuration + math.div($delayRange, $rectCount - 1) * ($i - 1); }
  }
}

@keyframes sk-waveStretchDelay {
  0%, 40%, 100% { transform: scaleY(0.4) }
  20% { transform: scaleY(1.0) }
}
