@import "variables";

// BUSD balance
[class^="styles__BalanceStyled"] {
  font-weight: 700 !important;
  font-family: $font-family-base !important;
}

// wallet address
[class^="styles__TextStyled"] {
  font-weight: 700;
  font-family: $font-family-base !important;
}

// button
button[class^="styles__ConnectButtonStyled"] {
  background: $color-accent;
  span[class^="styles__TextStyled"] {
    font-family: $font-family-accent !important;
    //color: $gray-900;
  }
}

// Connect button
div[class^="styles__ConnectButtonStyled"] {
  * {
    font-family: $font-family-sans-serif !important;
  }

  span {
    font-weight: 700;
  }
}

// modal
div[class^="WalletModalstyles"] {
  * {
    color: $color-primary;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    font-family: $font-family-sans-serif !important;
  }

  .modal-notification {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #73788C;
    margin-bottom: 24px;
    color: $color-secondary;
  }

  button[data-testid^="test-button"] {
    border: none;
    position: absolute;
    top: 16px;
    right: 16px;
    
    svg {
      fill: $color-secondary;
      height: 35px;
      width: 35px;
    }
  }

}