.section-sale {
  .fundraising-card {
    .text-round {
      text-transform: uppercase;
    }

    .fundraising-element {
      @include media-breakpoint-down(md) {
        margin-bottom: 10px;
      }
    }

    .progress {
      height: 20px;

      .bg-green {
        background-color: $color-accent !important;
      }

      .progress-bar {
        font-size: 1.3em;
        font-weight: bold;
        color: black;
      }
    }
  }

  .alloc {
    float: right;
  }

  .coin-swap {
    background: #eee;
    padding: 15px 15px;
    border-radius: 10px;
    font-weight: bold;

    input {
      text-align: right;
      font-size: 1.2em;

      @include media-breakpoint-down(lg) {
        margin-top: 10px;
      }
    }

    &.igu-token {
      .token-value {
        font-size: 1.2em;
        margin-left: auto;
      }
    }

    .slider-container {
      display: flex;
      width: 100%;
      height: 100%;
      align-self: flex-start;
      align-items: center;
      color: $color-accent;
    }

    .nouislider {
      width: 100%;
      min-height: 3px;
      padding-top: 0;
      padding-bottom: 0;

      .noUi-tooltip {
        border-color: $primary;
        background: $color-accent;
      }

      .noUi-connect {
        background: $color-accent;
      }

      @include media-breakpoint-down(lg) {
        .noUi-tooltip {
          display: none !important;
        }
      }

      @include media-breakpoint-down(lg) {
        margin-top: 10px;
      }
    }

  }

  .coin-swap-arrow {
    margin: 10px 0;
    text-align: center;
    position: relative;

    .fa-arrow-down {
      position: absolute;
      top: -5px;
    }
  }
}

.card-sale-not-allowed {
  ul.list {
    margin-bottom: 0;
  }

  // card-sale-not-allowed on mobile
  @include media-breakpoint-down(sm) {
    padding: 1.5rem 0.5rem;
    border-radius: 1.5rem;

    ul.list {
      padding-left: 0;
    }

    .icon.icon-lg {
      width: 2rem;
      height: 2rem;
    }

    .link-container {
      text-align: center;
    }

  }

  // card-sale-not-allowed on desktop large
  @include media-breakpoint-up(md) {
    .svg-inline--fa {
      height: 1.5em;
    }
  }
}

.tooltip-sale {
  ul {
    padding-left: 1rem;
  }

  small {
    float: none;
  }
}

@keyframes fadeInAnimation {
  0% {
    transform: translateY(+100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeInAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.last-transactions {
  padding: 10px;
  box-shadow: $box-shadow;
  border-radius: $card-inner-border-radius;

  ul {
    margin-bottom: 0;
  }

  .igu-token {
    height: 24px;
  }
}