/*
*
* =====================
* NAVBAR
* =====================
*
*/
.navbar {
    z-index: 4;
    border-bottom: 1px solid #ECECEF;
    justify-content: space-between;
    
    @include media-breakpoint-down(sm) {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .navbar-sidebar {
        // make sidebar wider for mobile, remove margin
        .sidebar-toggler {
            @include media-breakpoint-down(md) {
                padding: 5px 5px 5px 0;
                margin-right: -5px !important;
            }
        }
    }

    .navbar-brand {
        transition: all 0.3s;

        @include media-breakpoint-down(lg) {
            margin: 0 auto;
        }

        @include media-breakpoint-only(xxl) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            padding: 10px 0 0 0;
            margin-right: 0;
        }

        img {
            height: 23px;
        }
    }

    .user-menu {
        @include media-breakpoint-down(lg) {
            // margin: auto !important;
            .cSlPNT {
                margin: 0 5px;
            }
        }
        @include media-breakpoint-down(xl) {
            button {
                display: none;
            }
        }

        .user-balance {
            padding: 7px 10px;
            border-radius: 20px;
            background: #F3F6FE;
            @include media-breakpoint-down(sm) {
                padding: 7px 5px;
                .token-with-ticker {
                    img {
                        display: none;
                    }
                }
            }
        }

        #userInfo {
            &:hover {
                transform: scale(1.1);
            }
        }
    }

    .d-brand-partial {
        @media (min-width: 400px) {
            display: inline !important;
        }
    }

    .dropdown-menu {
        .lang-code {
            float: right;
            text-transform: uppercase;
            color: $text-muted;
            text-align: right;
        }

        min-width: 150px;

        .eth-address {
            font-size: 11px;
        }
    }

    .icon-hamburger {
        &:hover {
            transform: scale(1.1);
        }
    }

    @include media-breakpoint-down(xl) {
        position: fixed;
        width: 100%;

        .navbar-sidebar {
            flex: 0 0 50px;
        }

        .user-menu {
            margin: 0 !important;
        }
    }
}

.nav-link-icon {
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;

    .notification-badge {
        top: 1rem;
    }

    .notification-badge.notification-badge-number {
        top: .6rem;
    }
}

.input-group-navbar {
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;

    .form-control {
        background: $gray-200;
        border: none;
        box-shadow: none;
        font-size: $font-size-sm;
    }

    .btn {
        background: $gray-200;
    }

}

