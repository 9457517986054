/*
* ===================================================
*    Custom Bootstrapious Utils
* ===================================================
*/

/*
   Text utils 
   ------------------------
*/

.text-uppercase {
  @include uppercase;
}

.text-serif {
  font-family: $font-family-serif !important;
}

.text-shadow {
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.text-shadow-primary {
  text-shadow: 2px 2px 2px rgba($primary, 0.1);
}

/*
  Border - 0 for table cells
 ------------------------
*/

.border-0 {
  > th,
  > td {
    border: none !important;
  }
}

/*
  Star checkbox
 ------------------------
*/

.form-star {
  label {
    position: relative;
    min-height: $font-size-base * $line-height-base;
    margin-bottom: 0;
    cursor: pointer;
    color: $gray-300;

    &::before {
      content: "\f005";
      transition: all 0.2s ease;
      font-family: "Font Awesome 5 Free";
      font-size: 1.0625rem;
      font-weight: 900;
    }
  }

  > input:checked ~ label {
    color: theme-color("warning");
  }

  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
}

/*
   Colours and backgrounds
  ------------------------
*/

@each $color, $value in $theme-colors {
  .bg-#{"" + $color}-light {
    background-color: tint-color($value, 80%);
  }
}

@each $color, $value in $colors {
  .bg-#{$color}-light {
    background-color: tint-color($value, 80%);
  }
}

@each $color, $value in $theme-colors {
  .badge-#{$color}-light {
    color: $value;
    background-color: tint-color($value, 80%);

    .indicator {
      background: $value;
    }
  }
}

$gradientColors: map-merge(
  $map1: $theme-colors,
  $map2: $colors,
);

@each $color, $value in $gradientColors {
  .bg-hover-gradient-#{"" + $color} {
    position: relative;
    overflow: hidden;
    z-index: 2;

    &:hover {
      color: #fff !important;

      [class*="text-"]:not(.exclude) {
        color: #fff !important;
      }

      &::before {
        opacity: 1;
      }
    }

    &::before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      transition: all 0.5s;
      opacity: 0;
      background: linear-gradient(
        to left,
        $value,
        darken($value, 20%)
      ) !important;
      z-index: -1;
    }
  }

  .bg-hover-#{"" + $color} {
    transition: all 0.5s;

    &:hover {
      color: #fff !important;
      background: $value !important;

      [class*="text-"]:not(.exclude) {
        color: #fff !important;
      }
    }
  }

  .gradient-#{$color} {
    background: linear-gradient(
      to left,
      $value,
      darken($value, 20%)
    ) !important;
  }
}

.table-hover {
  tbody tr.no-stripe {
    background-color: transparent !important;
  }
}

.table-hover {
  tbody tr.no-hover {
    &:hover {
      background-color: transparent !important;
    }
  }
}

/*
   Transparent overlays
  ------------------------
*/

.light-overlay,
.dark-overlay,
.gradient-overlay {
  position: relative;
  overflow: hidden;

  .overlay-content {
    position: relative;
    z-index: 20;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    opacity: 0.3;
    background: #000;
  }
}

.light-overlay {
  &::after {
    opacity: 0.3;
    background: #fff;
  }
}

.gradient-overlay {
  &::after {
    opacity: 1;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 78%,
      rgba(0, 0, 0, 0.65) 100%
    );
  }
}

/*
   Other
  ------------------------
*/

.overflow-visible {
  overflow: visible !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.shadow-0 {
  box-shadow: none !important;
}

.left-box {
  box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.7);
}

.hover-scale {
  transition: $transition-base;

  &:hover {
    transform: scale(1.05);
  }
}

.hover-animate {
  transition: $transition-base;

  &:hover,
  &:focus {
    transform: translate3d(0, -2px, 0);
  }
}

.hover-scale-bg-image {
  .bg-image {
    transition: $transition-base;
  }

  &:hover,
  &:focus {
    .bg-image {
      transform: scale(1.05);
    }
  }
}

.close-absolute {
  position: absolute;
  top: 0;
  right: 0;
}

.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

img.bg-image {
  font-family: "object-fit: cover;";
  object-fit: cover;
}

.bg-cover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.full-screen {
  height: calc(100vh - #{$navbarHeight});
}

.mh-full-screen {
  min-height: 100vh;

  // hack for IE 11
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    height: 100vh;
  }
}

.list-group {
  border-radius: $list-group-border-radius;
}

@media print {
  .shadow-print-0 {
    box-shadow: none !important;
  }

  .px-print-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .p-print-0 {
    padding: 0 !important;
  }
}

// fixes for IE11
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .card {
    display: block;
  }

  .card-img-top,
  .card-img-bottom,
  .card-img {
    width: auto;
    min-width: 100%;
    height: auto;
  }
}
