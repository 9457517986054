/*
 *  Usage:
 *
      <div class="sk-double-bounce">
        <div class="sk-child sk-double-bounce1"></div>
        <div class="sk-child sk-double-bounce2"></div>
      </div>
 *
 */


.sk-double-bounce {
  width: $spinkit-size;
  height: $spinkit-size;
  position: relative;
  margin: $spinkit-spinner-margin;

  .sk-child {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $spinkit-spinner-color;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: sk-doubleBounce 2.0s infinite ease-in-out;
  }

  .sk-double-bounce2 {
    animation-delay: -1.0s;
  }
}

@keyframes sk-doubleBounce {
  0%, 100% { transform: scale(0) }
  50% { transform: scale(1.0) }
}
