/*
*
* =====================
* SIDEBAR
* =====================
*
*/

@import "mixins-custom";

.sidebar-menu {
    position: relative;
    background: $gray-100;
    font-size: $font-size-sm;
}

.sidebar-heading {
    margin-bottom: 0;
    padding: 0 24px;
    color: $color-secondary;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    @include uppercase;
    margin-bottom: 8px;

    @include media-breakpoint-down(lg) {
        font-size: 0.6em;
        padding-right: $spacer * .5;
        padding-left: $spacer * .5;
        text-align: center;
    }
}

.sidebar-link {
    @include font-accent();
    display: flex;
    padding: 12px 20px 12px 24px;
    transition: background 0.3s;
    text-decoration: none;
    align-items: center;
    gap: 8px;

    .svg-inline--fa {
        margin-right: 10px;
        font-size: 1.6em;
    }

    .new-menu-item {
        border-radius: 6px;
        padding: 2px 4px;
        text-transform: uppercase;
        background: #E64D4D;
        color: #FFFFFF;
    }

    &[data-bs-toggle="collapse"] {
        position: relative;

        &::before {
            display: block;
            position: absolute;
            width: 20px;
            height: 20px;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
            color: #aaa;
            content: "";
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' aria-labelledby='title' aria-describedby='desc' role='img' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EAngle Left%3C/title%3E%3Cdesc%3EA line styled icon from Orion Icon Library.%3C/desc%3E%3Cpath data-name='layer1' fill='none' stroke='%23aaa' stroke-miterlimit='10' stroke-width='5' d='M39 20.006L25 32l14 12.006' stroke-linejoin='round' stroke-linecap='round'%3E%3C/path%3E%3C/svg%3E");
            background-position: right center;
            transition: .2s;
        }

        &[aria-expanded='true'] {
            &::before {
                transform: translateY(-50%) rotate(-90deg);
            }
        }
    }

    &:hover {
        text-decoration: none;
        background: rgba($black, .03);
    }

    &:focus {
        background: rgba($color-accent, 0.3);
        &::before {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' aria-labelledby='title' aria-describedby='desc' role='img' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EAngle Left%3C/title%3E%3Cdesc%3EA line styled icon from Orion Icon Library.%3C/desc%3E%3Cpath data-name='layer1' fill='none' stroke='%23fff' stroke-miterlimit='10' stroke-width='5' d='M39 20.006L25 32l14 12.006' stroke-linejoin='round' stroke-linecap='round'%3E%3C/path%3E%3C/svg%3E");
        }
    }

    &.active {
        text-decoration: none;
        background: $color-darkGreen;

        span {
            color: #FFFFFF;
        }

        svg path {
            fill: #FFFFFF;
        }

        &::before {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' aria-labelledby='title' aria-describedby='desc' role='img' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EAngle Left%3C/title%3E%3Cdesc%3EA line styled icon from Orion Icon Library.%3C/desc%3E%3Cpath data-name='layer1' fill='none' stroke='%23fff' stroke-miterlimit='10' stroke-width='5' d='M39 20.006L25 32l14 12.006' stroke-linejoin='round' stroke-linecap='round'%3E%3C/path%3E%3C/svg%3E");
        }
        .new-menu-item {
            background: #FFFFFF;
            color: #E64D4D !important;
        }
    }

    .sidebar-link-title {
        display: inline-block;
        transition: all 0.3s;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }
}

.sidebar-menu {
    .sidebar-link.active {
        background: tint-color($color-accent, 30%);
    }
}

.sidebar {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1 0 224px;
    max-width: 224px;
    transition: all 0.2s;
    background: #FFFFFF;
    border-right: 1px solid #ECECEF;
    z-index: 2;
    padding: 24px 0;

    &.shrink {
        width: 6rem;
        text-align: center;

        .sidebar-link {
            display: block;
            padding-right: 0.2rem;
            padding-left: 0.2rem;
            font-size: 0.75rem;

            .sidebar-link-title {
                display: block;
                font-size: 0.75rem;

                @include media-breakpoint-down(sm) {
                    font-size: 0.6rem;
                }

            }

            .svg-inline--fa {
                margin: 0 !important;
            }

            &[data-bs-toggle="collapse"] {
                padding-bottom: 1.5rem;

                &::before {
                    position: absolute;
                    top: auto;
                    right: 50%;
                    bottom: .25rem;
                    left: auto;
                    transform: translateX(50%) rotate(-90deg);
                }

                &[aria-expanded='true'] {
                    &::before {
                        transform: translateX(50%) rotate(90deg);
                    }
                }
            }
        }

        .sidebar-heading {
            padding-right: $spacer * .5;
            padding-left: $spacer * .5;
            text-align: center;
        }

        .sidebar-menu {
            font-size: $font-size-sm;
        }

        .collapse,
        .collapsing {
            .sidebar-link {
                padding-right: 0.2rem !important;
                padding-left: 0.2rem !important;
                transition: none;
            }
        }
    }

    @include media-breakpoint-down(xl) {
        overflow: hidden;
        width: 6rem;
        height: 100vh;
        margin-left: -6rem;
        transition: all 0.4s;
        text-align: center;
        display: none;
        position: fixed;
        z-index: 9999;
        margin-top: 73px;

        &.show {
            display: block;
        }

        .sidebar-link {
            display: block;
            padding-right: 0.2rem;
            padding-left: 0.2rem;

            .sidebar-link-title {
                display: block;
                font-size: 0.75rem;
            }

            svg {
                margin: 0 !important;
            }
        }

        .sidebar-heading {
            padding-right: $spacer * .5;
            padding-left: $spacer * .5;
            text-align: center;
        }

        .sidebar-menu {
            font-size: $font-size-sm;
        }
    }

    &.show {
        margin-left: 0;
    }
}