/*
* ==========================================================
*     SPINKIT PRELOADERS
* ==========================================================
*/

$spinkit-spinner-margin: 0 !default;
$spinkit-size: 1rem !default;
$spinkit-spinner-color: $primary !default;

@import "spinners/1-rotating-plane",
"spinners/2-double-bounce",
"spinners/3-wave",
"spinners/4-wandering-cubes",
"spinners/6-chasing-dots",
"spinners/7-three-bounce",
"spinners/8-circle",
"spinners/9-cube-grid",
"spinners/10-fading-circle",
"spinners/11-folding-cube";

/*
* ==========================================================
*     PRELOADERS
* ==========================================================
*/

.spinner-element {
  margin-left: auto !important;
  margin-right: auto !important;
}

.spinner-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all .5s ease-in-out;
  background-color: $white;
  z-index: 999999;

  &.opacity-0 {
    visibility: hidden;
  }
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  .spinner-refresh {
    .spinner-refresh-icon {
      padding-right: 5px;
    }
  }
}

// loader inside buttons is position absolute
.btn, .user-balance {
  .spinner-element {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -$spinkit-size / 2 !important;
    margin-left: -$spinkit-size / 2 !important;
  }
}

.btn-primary {
  .sk-wave .sk-rect {
    background-color: white !important;
  }
}