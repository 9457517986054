.section-claim {
    .claiming-info{
        ul {
            list-style: none;
            list-style-type: none;
            padding-inline-start: 0;
            margin-block-start: 0;
        }
        .info-features {
            margin-bottom: 0;
            display: flex;
            flex-direction: column;
            gap: 12px;

            li {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 0;
                gap: 12px;
                
                .info-features-details {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: $color-primary;
                    overflow-wrap: anywhere;
                }
            }


            &.warning {
                display: flex;
                flex-direction: row;
                padding: 12px;
                gap: 6px;
                background: rgba(255, 208, 87, 0.2);
                border-radius: 8px;

                li {
                    align-items: flex-start;

                    .info-features-details {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        color: #C28C00;
                    }
                }

            }
        }
    }
    .claiming-panel{
        .main-info{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 16px;
            gap: 8px;
            border: 1px solid #ECECEF;
            border-radius: 12px;

            .main-info-element {
                width: 100%;
                
                .main-info-element-title {
                    span {
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 32px;
                    }
                }
                .main-info-element-progress {
                    display: flex;
                    flex-direction: row;
                    gap: 8px;
                    width: 100%;

                    .progress {
                        height: 20px;
                        border-radius: 0.6rem;
                        flex: 1;
                        .progress-bar {
                            background-color: #40BF95 !important;
                        }
                    }

                    .progress-detail {
                        color: $color-secondary;
                        font-weight: 700;
                        font-size: 15px;
                        line-height: 24px;
                    }
                }
            }

        }
        .vesting-info{
            display: flex;
            flex-direction: column;
            gap: 16px;

            &-wrapper {
                display: flex;
                flex-direction: column;
                padding: 0px 0px 12px;
                gap: 12px;

                &:not(:last-child) {
                    border-bottom: 1px solid #ECECEF;
                }    
            }

            .vesting-info-element{
                display: flex;
                justify-content: space-between;
                font-weight: 700;
                font-size: 15px;
                line-height: 24px;
                color: $color-secondary;

                .vesting-info-num{
                    color: $color-primary;

                    &.available-to-claim {
                        font-size: 18px;
                        line-height: 26px;
                    }
                }
            }
            .vesting-line{
                border-bottom: 1px solid #eee;
                height: 1px;
                width: 100%;
            }
        }

        .btn-buy {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 12px 24px;
            gap: 6px;
            background: #BDFFE9;
            box-shadow: 0px 4px 0px #86DFC2;
            border-radius: 1000px;
            font-weight: 800;
            font-size: 15px;
            line-height: 20px;
            color: $color-primary;
            border: unset !important;
            transition: all 500ms;

            &:hover {
                background: #99FFDD;
            }
        }
    }
    

}