// VanillaJS Datepicker css
// @import "../../node_modules/vanillajs-datepicker/dist/css/datepicker-bs4.min.css";

// tooltip
.tippy-box {
  background: #FFFFFF !important;
  border: 1px solid #ECECEF !important;
  border-radius: 8px !important;
  padding: 12px !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: $color-primary !important;
  filter: drop-shadow(0px 2px 0px #DEDFE3) !important;

  .tippy-arrow {
  	color: #FFFFFF !important;
  }

}

.react-select-2-listbox {
  z-index: 1000 !important;
}